
// config page
const config = {
    preload: {
        imgSelector: '._preload-img',
        delay: 1200
    },
    top: {
        offset: 0
    },
    nav: {
        lock: false
    },
    mqSet: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1420
    },
    isTouch: Modernizr.touch,
    isEditmode: $('body').hasClass('editmode')
};

$(function() {

});

// what shall we do on error
window.onerror = function(error) { 
    $('.loader-container').fadeOut(200);
    inView();
}

// page skeleton
class Page {
    
    constructor(el) {
        this.DOM = {};
        this.init();
    }
    
    init() {
        this.layout(); 
        this.initVendorPlugins();
        this.initPagePlugins();
        this.initEvents();
    }
    
    layout(){

    }

    initVendorPlugins() {
        if(!config.isEditmode){ // exclude from editmode
            owl();
            magnific();
            swiper();
            misc();
            masonry();
            gmap();
            animejs();
            parallax();
        }
        bootstrap();
        editmode();
    }
    
    initPagePlugins() {
        isLoading();
        watchUnload();
        scrollNavigation();
        fixedNav();
        animation();
        inView();
    } 
    
    initEvents(){

    }

}

const animation = function pageAnimation() {
    const svgCircuit = parseInt($('.caption-border').height()*2 + $('.caption-border').width()*2);
    const lineDrawing = anime({
      targets: '.caption-border path',
      strokeDashoffset: [svgCircuit,0],//[anime.setDashoffset, 0],
      easing: 'easeInOutCubic',
      strokeDasharray:[svgCircuit,svgCircuit],
      duration: 2500,
      delay: 1500,
      //direction: 'alternate',
      //loop: true
    });
}
 
const fixedNav = function fixedNavigation() {
    const elm = $('.navbar-wrapper');
    if (!$(elm)[0]) {
        return false;
    }
    const navHeight = elm.height();
    const elmFixedClass = 'navbar-fixed';
    const elmPos = 0;//elm.offset().top;
    const elmFixedBreakpoint = 1;//$('.top-wrapper').height();

    /*
    const navbarRevealElm = '.navbar-reveal path';
    let navbarReveal = anime.timeline();

    // animacja tla pod menu (svg)
    navbarReveal.add({
        targets: navbarRevealElm,
        d: $(navbarRevealElm).data('path-1'),
        easing: 'easeInCubic',
        duration: 210
    }).add({
        targets: navbarRevealElm,
        d: $(navbarRevealElm).data('path-2'),
        easing: 'easeOutCubic',
        duration: 420
    });
    navbarReveal.pause();
    navbarReveal.reverse();
    */
    
    function setNavbar() {
        if (!config.nav.lock) {
            
            if ($(window).width() > config.mqSet.lg) {
                const fixIT = $(window).scrollTop() > elmPos + elmFixedBreakpoint;
                
                if (fixIT) {
                    if (!elm.find('.navbar').hasClass(elmFixedClass)) {
                        elm.find('.navbar').addClass(elmFixedClass);
                        //navbarReveal.play();
                        //navbarReveal.reverse();
                    }
                } else {
                    if (elm.find('.navbar').hasClass(elmFixedClass)) {
                        elm.find('.navbar').removeClass(elmFixedClass);
                        //navbarReveal.play();
                        //navbarReveal.reverse();
                    }
                }
            } else {
                if (elm.find('.navbar').hasClass(elmFixedClass)) {
                    elm.find('.navbar').removeClass(elmFixedClass);
                }
            }
        }
    }
    setNavbar();
    $(window).scroll(_.throttle(() => {
        setNavbar();
    }, 250));
    $(window).resize(_.throttle(() => {
        setNavbar();
    }, 250));
    
};

const scrollTo = function scrollToElement(elm) {
    if (!$(elm.target)[0]) {
        return false;
    }
    const posOld = parseInt($(window).scrollTop());
    const posElm = parseInt($(elm.target).offset().top);
    let posDiff = posElm - posOld;
    posDiff = posDiff < 0 ? posDiff * -1 : posDiff;

    $("html, body").bind("scroll mousedown DOMMouseScroll mousewheel", function () {
        $('html, body').stop();
    });

    $('html,body').animate({ scrollTop: posElm - config.top.offset + 1 }, {
        duration: elm.speed ? elm.speed : 900,
        easing: elm.easing ? elm.easing : 'easeInOutQuint',
        complete: function () {}
    }, function () {
        $("html, body").unbind("scroll mousedown DOMMouseScroll mousewheel");
    });
    return false;
};

const scrollNavigation = function initScrollNavigation() {
    $(".scroll-me, .aside-fixed-navigation a, .scrollspy-aside a").each((key, elm) => {
        $(elm).on('click', e => {
            //console.log($(e.currentTarget));
            if ($(elm).is('a:not([href*="#"])')) {} else {
                e.preventDefault();
                let target = $(elm).attr('href');
                if (target.substring(target.indexOf('#')) != window.location.pathname && target.indexOf('#') > 0) {
                    window.location.href = target;
                }
                target = target.substring(target.indexOf('#'));
                scrollTo({ target: target });
            }
        });
    });
    if (window.location.hash) {
        $(window).scrollTop(0);
        let target = window.location.hash;
        scrollTo({ target: target, speed: 1200 });
        setTimeout(function () {
            scrollTo({ target: target, speed: 1200 });
        }, 1000);
    }
};

const bootstrap = function initBootstrap() {
    $('[data-toggle="tooltip"]').tooltip();
    //$('[data-spy="scroll"]').scrollspy();

    // navbar active set on show  
    $('.navbar-wrapper .navbar .navbar-collapse').on('show.bs.collapse', function () {
        $('body').addClass('navbar-active');
    });
    $('.navbar-wrapper .navbar .navbar-collapse').on('hide.bs.collapse', function () {
        $('body').removeClass('navbar-active');
    });
    
    // scrollspy
    $('body').scrollspy({target: '#scrollspy-aside' });
    $(window).on('activate.bs.scrollspy', function (e) {
        //history.replaceState({}, "", $('#scrollspy-aside .nav-item a.nav-link.active:last').attr("href"));
    });
    
    // active dla panel w accordion (dodjaemy accordion class)
    $(".accordion .card-header").click(function(){
        $(".card-header").not(this).parent().removeClass('active');
        $(this).parent().toggleClass('active');
    });
    // otwarcie panelu po hashu
    var hash = window.location.hash;
    if (hash) {
        // Get panel header element
        var requestedPanel = $(hash);
        if ($(hash).length) {
            $(".accordion .card").removeClass('active');
            $(".accordion .card .collapse").removeClass('show');
            $(hash).addClass('active');
            $(hash).find('.collapse').addClass('show');
        }
    }
    
    // swipe carousel
    $('[data-ride="carousel"]').swipe({

	  swipe: function(event, direction, distance, duration, fingerCount, fingerData) {

	    if (direction == 'left') $(this).carousel('next');
	    if (direction == 'right') $(this).carousel('prev');

	  },
	  allowPageScroll:"vertical"

	}); 
    
  	// carousel progress bar
    const carouselProgressBar = $('.carousel-progress-bar');
    const carouselElm = $('.carousel-with-progress-bar[data-ride="carousel"]');
    if ( carouselProgressBar.length) {
        let carouselProgressPercent = 0;
        let carouselProgressInterval = parseInt($('.carousel-progress-bar').parent().data('carousel-progress-interval'));//30 takes about 6s, interval=20 takes about 4s
        let carouselPause = $('.carousel-progress-bar').parent().data('pause'); // omit pause on hover with data-pause false
        $('.carousel-indicators li, .carousel-control').click(function (){
            carouselProgressBar.css({width:0.5+'%'});
        });
        carouselElm.on('slide.bs.carousel', function (){
            carouselProgressPercent = 0;
        });
        function calculateProgressBarCarousel() {
            carouselProgressBar.css({width:carouselProgressPercent+'%'});
            carouselProgressPercent = carouselProgressPercent +0.5;
            if (carouselProgressPercent>=100) {
                carouselProgressPercent=0;
                carouselElm.carousel('next');
            }
        }
        let barInterval = setInterval(calculateProgressBarCarousel, carouselProgressInterval);
        if (!config.isTouch && carouselPause!==false) {
            carouselElm.hover( function(){
                    clearInterval(barInterval);
                },
                function(){
                    barInterval = setInterval(calculateProgressBarCarousel, carouselProgressInterval);
                }
            );
        }
    }
     
};

const owl = function initOwlCarousel() {
    $.each($('[data-trigger="owl-carousel"]'), (key, elm) => {
        var responsive = {};
        $.each($(elm).data("responsive"), (mq, item) => {
            responsive[config.mqSet[mq]] = { items: item };
        });

        $(elm).owlCarousel({
            smartSpeed: $(elm).data("smart-speed") ? parseInt($(elm).data("smart-speed")) : 500,
            responsive: responsive,
            margin: $(elm).data("margin") ? parseInt($(elm).data("margin")) : false,
            dots: $(elm).data("dots") ? $(elm).data("dots") : false,
            nav: $(elm).data("nav") ? $(elm).data("nav") : false,
            autoHeight: $(elm).data("autoheight") ? $(elm).data("autoheight") : false,
            dotsEach: $(elm).data("dots-each") ? parseInt($(elm).data("dots-each")) : false,
            autoplay: $(elm).data("autoplay") ? $(elm).data("autoplay") : false,
            autoplayTimeout: $(elm).data("autoplay-timeout") ? $(elm).data("autoplay-timeout") : 5000,
            loop: $(elm).data("loop") ? $(elm).data("loop") : false,
        });
        if ($(elm).data("external-nav") == true) {
            $(elm).parent().find('.carousel-control.left').click(function () {
                $(elm).trigger('prev.owl.carousel');
            });
            $(elm).parent().find('.carousel-control.right').click(function () {
                $(elm).trigger('next.owl.carousel');
            });
        }
    });
};

const magnific = function initMagnificPopup() {
    $.each($('[data-trigger="magnific-popup"]'), (key, elm) => {
        $(elm).magnificPopup({
            delegate: $(elm).data("delegate") ? $(elm).data("delegate") : false,
            type: 'image',
            removalDelay: 500,
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            gallery: {
                enabled: $(elm).data("gallery-enabled") ? $(elm).data("gallery-enabled") : false
            },
            image: {
                verticalFit: true,
                titleSrc: function (item) {
                    return item.el.data('caption') ? item.el.data('caption') : '';
                }
            },
            callbacks: {
                beforeOpen: function () {
                    if (this.st.el.attr('data-effect') || $(elm).data("effect")) {
                        this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                        this.st.mainClass = this.st.el.attr('data-effect') ? this.st.el.attr('data-effect') : $(elm).data("effect");
                    }
                },
            }, 
            zoom: {
                enabled: $(elm).data("zoom-enabled") ? $(elm).data("zoom-enabled") : false
            },
            closeOnContentClick: true,
            closeBtnInside: false,
            midClick: true
        });
    });
};

const swiper = function initSwiper() {
    $.each($('[data-trigger="swiper"]'), (key, elm) => {
        let swiperItem = new Swiper(elm, {
            effect: $(elm).data("effect") ? $(elm).data("effect") : false,
            grabCursor: true,
            slidesPerView: $(elm).data("slides-per-view") ? $(elm).data("slides-per-view") : 'auto',
            direction: $(elm).data("direction") ? $(elm).data("direction") : 'auto',
            loop: $(elm).data("loop") ? $(elm).data("loop") : false,
            centeredSlides: $(elm).data("center-slides") ? $(elm).data("center-slides") : false,
            cubeEffect: {
                shadow: false,
                slideShadows: false
            },
            coverflowEffect: {
                rotate: 25,
                stretch: 200,
                depth: 300,
                modifier: 1,
                slideShadows: true
            },
            pagination: $(elm).data("pagination") ? {
                el: '.swiper-pagination',
                clickable: true,
                type: $(elm).data("pagination-type") ? $(elm).data("pagination-type") : 'bullets',
                dynamicBullets: $(elm).data("pagination-dynamic-bullets") ? $(elm).data("pagination-dynamic-bullets") : false
            } : false,
            navigation: $(elm).data("navigation") ? {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            } : false
        });
        $(window).resize(function () {
            //  swiperItem.update();
        });
        $(window).trigger("resize");
    });
};

const masonry = function initMasonryGridAndFilters() {
    $.each($('[data-trigger="masonry"]'), (key, elm) => {
        const itemSelector = $(elm).data("item-selector") ? $(elm).data("item-selector") : ".item";
        const filterSelector = $(elm).data("filter-selector") ? $(elm).data("filter-selector") : ".filters";
        
        $(elm).multipleFilterMasonry({
            itemSelector: itemSelector,
            filtersGroupSelector: filterSelector,
            percentPosition: $(elm).data("percent-position") ? $(elm).data("percent-position") : false,
            columnWidth: $(elm).data("grid-sizer") ? $(elm).data("grid-sizer") : false, 
            selectorType: $(elm).data("selector-type") ? $(elm).data("selector-type") : false, // "list" - pojedyncze wartosci + hash - domyslnie buttony z opcja multi wyboru
        });
        $(filterSelector + " .button").find("input").change(function(){
            $(this).parent().toggleClass("active");
        });
    });

};

const gmap = function initGoogleMap() {  
    $.each($('[data-trigger="google-map"]'), (key, elm) => { 
        const latLng = $(elm).data("lat-lng");
        var map = new google.maps.Map(document.getElementById('map'), {
          zoom: $(elm).data("zoom") ? $(elm).data("zoom") : 11,
          center: latLng,
          styles: [
            {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
            {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
            {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
            {featureType: 'administrative.locality',elementType: 'labels.text.fill',stylers: [{color: '#d59563'}]},
            {featureType: 'poi',elementType: 'labels.text.fill',stylers: [{color: '#d59563'}]},
            {featureType: 'poi.park',elementType: 'geometry',stylers: [{color: '#263c3f'}]},
            {featureType: 'poi.park',elementType: 'labels.text.fill',stylers: [{color: '#6b9a76'}]},
            {featureType: 'road',elementType: 'geometry',stylers: [{color: '#38414e'}]},
            {featureType: 'road',elementType: 'geometry.stroke',stylers: [{color: '#212a37'}]},
            {featureType: 'road',elementType: 'labels.text.fill',stylers: [{color: '#9ca5b3'}]},
            {featureType: 'road.highway',elementType: 'geometry',stylers: [{color: '#746855'}]},
            {featureType: 'road.highway',elementType: 'geometry.stroke',stylers: [{color: '#1f2835'}]},
            {featureType: 'road.highway',elementType: 'labels.text.fill',stylers: [{color: '#f3d19c'}]},
            {featureType: 'transit',elementType: 'geometry',stylers: [{color: '#2f3948'}]},
            {featureType: 'transit.station',elementType: 'labels.text.fill',stylers: [{color: '#d59563'}]},
            {featureType: 'water',elementType: 'geometry',stylers: [{color: '#17263c'}]},
            {featureType: 'water',elementType: 'labels.text.fill',stylers: [{color: '#515c6d'}]},
            {featureType: 'water',elementType: 'labels.text.stroke',stylers: [{color: '#17263c'}]}
          ]
        });
        if( $(elm).data("marker") ){ 
            const marker = new google.maps.Marker({
                position: latLng,
                map: map,
                draggable: false,
                icon: {
                    url: $(elm).data("marker").url,
                    anchor: new google.maps.Point($(elm).data("marker").width/2,$(elm).data("marker").height),
                    scaledSize: new google.maps.Size($(elm).data("marker").width,$(elm).data("marker").height)
                }
            });
        } else {
            const marker = new google.maps.Marker({
                position: latLng,
                map: map,
            });
        }
    });
};

const misc = function initMiscPlugins() {
    // obsluga css object-fit
    objectFitImages();

    // parallax
    jarallax(document.querySelectorAll('.jarallax'), {
        onScroll: function (calculations) {
            //console.log(calculations);
        }
    });
    
    // lettering / dzielimy na wyrazy lub znaki
    $('[data-split="chars"]').lettering();
    $('[data-split="words"]').lettering('words');
    
    // sticky
    var sticky = new Sticky('[data-sticky]');
    

};

const parallax = function initParallax() {
    $.each($('[data-trigger="parallax"]'), (key, elm) => {
        new Parallax(elm);
    });
};

const animejs = function initAnime() {
    $.each($('[data-trigger="anime"]'), (key, elm) => {
        const animationSet = $(elm).data("animation-set");
        const animeAnimation = anime(
            _.extend(
                {
                    targets: ($(elm).data("targets")) ? $(elm).data("targets") : elm,
                    easing: ($(elm).data("easing")) ? $(elm).data("easing") : 'easeOutElastic',
                    loop: ($(elm).data("loop")) ? $(elm).data("loop") : false,
                    direction: ($(elm).data("direction")) ? $(elm).data("direction") : 'normal',
                    //translateX: [100,300],
                   // translateY: [25,-25],
                    duration: ($(elm).data("duration-step"))
                        ? function(el, i, l) { 
                            return ( ($(elm).data("duration")) ? $(elm).data("duration") : 1000) + (i * $(elm).data("duration-step") ); 
                        }
                        : ($(elm).data("duration")) ? $(elm).data("duration") : 1000,
                    delay: ($(elm).data("delay-step"))
                        ? function(el, i, l) { 
                            return ( ($(elm).data("delay")) ? $(elm).data("delay") : 0) + (i * $(elm).data("delay-step") ); 
                        }
                        : ($(elm).data("delay")) ? $(elm).data("delay") : 0,

                },
                animationSet
            )
        );
    });
};

const validRealUser = function validFormRealUser(form, securityVal, securityField, minCharNumber, minMouseMove){
    var form = form;
    var securityVal = securityVal;
    var securityField = securityField;
    var mouseMove = 0;
    var mouseTestPassed = false;
    var keyboardTestPassed = false;
    var minCharNumber = minCharNumber || 3;
    var minMouseMove = minMouseMove || 3;
    var oForm = $(form);

    //console.log(formId+" " +securityVal+" " +securityField+" " +minCharNumber+" " +minMouseMove);
    
    if(minMouseMove == -1 && minCharNumber == -1) {
        alert("Przynajemniej jeden parametr jest wymagany");
    }
    else if (minCharNumber == -1){
        keyboardTestPassed = true; 
    }else if(minMouseMove == -1){
        mouseTestPassed = true;
    }
    if(!mouseTestPassed){
        oForm.mousemove(function(event){
            mouseMove++;
            if(mouseMove > minMouseMove){
                mouseTestPassed = true;
                oForm.unbind('mousemove');
            }
            isOkay();
        });
    }
    if(!keyboardTestPassed){
        var charNumber = 0;
        oForm.keypress(function(event){
            charNumber++;
            if(charNumber > minCharNumber){
                keyboardTestPassed = true;
                oForm.unbind('keypress');
            }
            isOkay();
        });
    }

    function isOkay(){
        if (keyboardTestPassed || mouseTestPassed) {
            oForm.append("<input type='hidden'  name='" + securityField + "' value='" + securityVal + "'/>");
        }
    }
}

const editmode = function initEditmode() {
    $('.pimcore_area_composer-layout').each(function(i){
        $(this).hover(function(){
            $('.pimcore_area_composer-layout').removeClass('active');
            $('.pimcore_area_composer-layout').eq(i).addClass('active');
        })
    });
}

const inView = function activeInView() {
    $.each($('[data-trigger="in-view"]'), (key, elm) => {
        const waypoints = $(elm).waypoint( (direction) => {
            if(direction == 'down'){
                $(elm).addClass( ($(elm).data("class")) ? $(elm).data("class") : 'active' );
            }
        }, { 
            offset: ($(elm).data("offset")) ? $(elm).data("offset") : '92%',
        });

        // propagujemy sprawdzanie active do elementow wskazanych
        if(elm.hasAttribute("data-propagate-to")){
            $(elm).find($(this).data('propagate-to')).each( (i,elmPropagated) => {
                $(elmPropagated).addClass( ($(elm).data("class")) ? $(elm).data("class") : 'active' );
            });
        }
    });
};

const isLoaded = function pageIsLoaded() { // dedicated project functions
    
    const loaderShutterElm = '.loader-shutter path';
    let loaderShutter = anime.timeline();

    setTimeout(function(){
        
        anime({
            targets: '.loader-container .logo',
            translateY: -100,
            easing: 'easeOutCubic',
            opacity: 0,
            scale: .5,
        });
        
        anime({
            targets: '.loader-container .circular',
            translateY: -100,
            easing: 'easeOutCubic',
            opacity: 0,
            scale: .5,
        });
        
        loaderShutter.add({
            targets: loaderShutterElm,
            d: $(loaderShutterElm).data('path-1'),
            easing: 'easeInCubic',
            duration: 350,
            complete: function(){
                
            }
        }).add({
            targets: loaderShutterElm,
            d: $(loaderShutterElm).data('path-2'),
            easing: 'easeOutCubic',
            duration: 420
        });
        //loaderShutter.pause();
       // loaderShutter.reverse();

        loaderShutter.complete = function() {
            $('body').removeClass('is-loading');
            $('body').addClass('is-loaded');
        }
        
        $('body').on('activate.bs.scrollspy', function (e) {

        //history.replaceState({}, "", $("a[href^='#']", e.target).attr("href"));
        });
        //$('[data-spy="scroll"]').each(function () {
            //$(this).scrollspy('refresh')
        //});
         
    }, config.preload.delay);
};

const isLoading = function pageIsLoading() {

};

const watchUnload = function watchPageUnload() {
    
    // ignore click in editmode
    if( $("body").hasClass('editmode') ){
        return true; 
    }
    
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){ // disable for firefox
        
    }else{
        // ignore in iframe
        if( window.self === window.top ){
            //return true;
        }

        $("a").not(".a-js").not("[data-trigger]").not("[data-gallery]").click( function(event) {

            var href = $(this).attr("href");

            if(href == undefined) return true;  

            // ignore redirects to external pages
            if (href.match(/^https?:\/\/|^http?:\/\/|^www|^mailto:|^tel:|^fax:|^\#|^\$/)) return true;  
            event.preventDefault();

            $('body').fadeOut(250);

            setTimeout(function(){
                window.location = href;
            }, 250);
        });
    }
};

window.onload = new Page();

// load page
$( config.preload.imgSelector ).imagesLoaded()
.always( function( instance ) {
    isLoaded();
})
.progress( function( instance, image ) {
    var result = image.isLoaded ? 'loaded' : 'broken';
    //console.log( 'image is ' + result + ' for ' + image.img.src );
});

jQuery(document).ready(function() {
    jQuery('.video-carousel').on('click', '.item', function () {
        var video_link = jQuery(this).data('src');
        jQuery('.video-carousel .item').removeClass('active');
        jQuery(this).addClass('active');
        jQuery(".movie-presentation").html('<iframe width="100%" height=520" src="' + video_link + '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
    });
});
